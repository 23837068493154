import React, { useState, useEffect } from "react";
import logoImage from "../Images/logo.png";
import "../styles/Header.css";
import { FaGithub, FaLinkedin, FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      // Add overflow hidden when the menu is open
      document.body.style.overflow = 'hidden';
    } else {
      // Remove overflow hidden when the menu is closed
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  function scrollToSection(event) {
    event.preventDefault(); // Prevents the default link behavior
  
    const targetElement = document.querySelector(event.target.getAttribute('href'));
    const offset = -80; // Set the desired offset here
  
    if (targetElement) {
      const topOffset = targetElement.offsetTop + offset;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth' // Enables smooth scrolling
      });
    }

    setIsOpen(false); // Close the menu after clicking on an item
    
  }

  function closeMenu(event) {
    setIsOpen(false); // Close the menu after clicking on an item
  }

  return (
    <section id="header-container">
      <div className="menu-container">
        <button className="hamburger" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </button>
        <ul className={`menu-items ${isOpen ? 'active' : ''}`}>
          <li>
            <a href="#home"onClick={(event) => closeMenu(event)}>Home</a>
          </li>
          <li>
            <a href="#skills-title" onClick={(event) => scrollToSection(event)}>Skills</a>
          </li>
          <li>
            <a href="#qualification-title" onClick={(event) => scrollToSection(event)}>Qualifications</a>
          </li>
          <li>
            <a href="#project-title" onClick={(event) => scrollToSection(event)}>Projects</a>
          </li>
          <li>
            <a href="#contact-title" onClick={(event) => scrollToSection(event)}>Contact me</a>
          </li>
          <li>
            <a href="https://github.com/VadymKaletnykDev" target="_blank" rel="noopener noreferrer">
              <FaGithub />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/vadym-kale1998/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          </li>
        </ul>
        <img src={logoImage} alt="Vadym" className="logo" />
      </div>
    </section>
  );
};

export default Header;

