import React from 'react';
import Header from './pages/Header';
import Home from './pages/Home'
import Skills from './pages/Skills';
import Qualificatications from './pages/Qualificatications';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Footer from './pages/Footer';



const HomePage = () => {
  return (
    <>
      <Header />
      <Home />
      <Skills />
      <Qualificatications/>
      <Projects/>
      <Contact/>
      <Footer/>
    </>
  );
};

export default HomePage;
