/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "../styles/Skills.css";
import logoHTML from '../Images/html.png'
import logoCSS from '../Images/css.png'
import logoJS from '../Images/js.png'
import logoReact from '../Images/react.png'
import logoJava from '../Images/java.png'
import logoGit from '../Images/git.png'
import logoPython from '../Images/python.png'
import logoC from '../Images/C.png'
import logoFirebase from '../Images/firebase.png'
import logoMongoDB from '../Images/mongoDb.png'
import logoSpringBoot from '../Images/spring-boot.png'


const Skills = () => {

  return (
    <>
    <h1 id="skills-title"><span style={{ color: "#1e90ff"}}>S</span>kills</h1>
    <h2 id="skills-subtitle">My favorite skills</h2>
    <section id="skills">
      <div className="header-left-skills">
      <h1><span style={{ color: "#1e90ff"}}>Front</span> End </h1>
        <div className="image-container-front-end">
          <div>
        <img src={logoHTML}  alt="HTML"/>
        <span>HTML</span>
        </div>
        <div>
        <img src={logoCSS} alt="CSS" />
        <span>CSS</span>
        </div>
        <div>
        <img src={logoJS} alt="JS" />
        <span>JavaScript</span>
        </div>
        <div>
        <img src={logoGit} alt="Git" />
        <span>Github</span>
        </div>
        <div>
        <img src={logoReact} alt="React" />
        <span>React</span>
        </div>
      </div>
      </div>
      <div className="header-right-skills">
  <h1><span style={{ color: "#1e90ff"}}>Back</span> End</h1>
  <div className="image-container-back-end">
    <div>
      <img src={logoJava} alt="JAVA" />
      <span>Java</span>
    </div>
    <div>
      <img src={logoPython} alt="Python" />
      <span>Python</span>
    </div>
    <div>
      <img src={logoC} alt="C" />
      <span>C/C#</span>
    </div>
    <div>
      <img src={logoMongoDB} alt="MongoDB" />
      <span>MongoDB</span>
    </div>
    <div>
      <img src={logoFirebase} alt="Firebase" />
      <span>Firebase</span>
    </div>
    <div>
      <img src={logoSpringBoot} alt="SpringBoot" />
      <span>SpringBoot</span>
    </div>
  </div>
</div>

    </section>
    </>
  );
};
export default Skills;
