import React, { useEffect, useState } from "react";
import "../styles/Home.css";
import VadymImage from "../Images/vadym.png";

const Header = () => {
  const [role, setRole] = useState("Full Stack Developer");

  useEffect(() => {
    const roles = ["Full Stack Developer","Back End Developer", "Front End Developer", "Data Analyst"];
    let index = 0;

    const intervalId = setInterval(() => {
      setRole(roles[index]);
      index = (index + 1) % roles.length;
    }, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function colorFirstWord(text) {
    const words = text.split(" ");
    if (words.length > 1) {
      return (
        <span>
          <span style={{ color: "#1e90ff" }}>{words[0]}</span>{" "}
          {words.slice(1).join(" ")}
        </span>
      );
    } else {
      return <span>{text}</span>;
    }
  }

  return (
    <section id="home">
      <div className="header-left">
        <h1>
          Hi, I'm <span style={{ color: "#1e90ff"}}>Vadym</span>
        </h1>
        <h3>{colorFirstWord(role)}</h3>
       
      </div>
      <div className="header-right">
        <img className="image-container" src={VadymImage} alt="Background" />
      </div>
    </section>
  );
};

export default Header;


