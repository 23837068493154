import React from "react";
import "../styles/Header.css";
import "../styles/Footer.css";
import { FaGithub, FaLinkedin } from "react-icons/fa";

const Footer = () => {

  return (
    <section id="header-container">
      <footer className="footer-container">
        <div className="footer-social">
          <a href="https://github.com/VadymKaletnykDev" target="_blank" rel="noopener noreferrer">
            <FaGithub />
          </a>
          <a href="https://www.linkedin.com/in/vadym-kale1998/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </a>
        </div>
        <p className="footer-text">© 2023 Vadym Kaletnyk. All rights reserved.</p>
      </footer>
    </section>
  );
};

export default Footer;

