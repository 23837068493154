import React from 'react';
import '../styles/Projects.css';
import logoHeirAtlas from "../Images/heir-atlas.jpeg";
import logoPurrRescue from "../Images/ecommerce.jpg";
import logoComingSoon from "../Images/coming-soon.jpg";
import resumePDF from "../pages/VadymKaletnykResume.pdf"; // Replace with the actual path to your PDF file

const Projects = () => {
  const handleCard1Click = () => {
    window.open('https://heiratlas.harrisburgu.cloud/', '_blank');
  };

  const handleCard2Click = () => {
    window.open('https://purr-rescue.web.app/', '_blank');
  };

  return (
    <>
      <h1 id="project-title">
        <span style={{ color: '#1e90ff' }}>P</span>rojects
      </h1>
      <h2 id="project-subtitle">My projects</h2>
      <section id="projects">
        <div className="card" onClick={handleCard1Click}>
          <img src={logoHeirAtlas} alt="Project 1" />
          <button className="card-button">Heir Atlas Project</button>
        </div>
        <div className="card" onClick={handleCard2Click}>
          <img src={logoPurrRescue} alt="Project 2" />
          <button className="card-button">Purr rescue eCommerce Website</button>
        </div>
        <div className="card">
          <img src={logoComingSoon} alt="Project 3" />
          <button className="card-button">Forex Agenda for traders</button>
        </div>
      </section>
      <section id="resume-download-section">
      <a href={resumePDF} target="_blank" rel="noopener noreferrer">
      <button id="resume-download-button">
            View Resume
          </button>
        </a>
      </section>
    </>
  );
};

export default Projects;

