import React from 'react';
import '../styles/Qualifications.css';

const Qualifications = () => {
  return (
    <>
      <h1 id="qualification-title">
        <span style={{ color: '#1e90ff' }}>Q</span>ualifications
      </h1>
      <h2 id="qualification-subtitle">My qualifications</h2>
      <section id="qualifications">
        <div className="header-left-qualifications">
          <h1>
            <span style={{ color: '#1e90ff' }}>E</span>ducation
          </h1>
          <div className="school">
            <h3>Istituto Tecnico Fauser (High School)</h3>
            <p>Years Attended: 2012-2017
            <br></br>Diploma in IT expert</p>
          </div>
          <div className="school">
            <h3>Harrisburg Area Community College (HACC)</h3>
            <p>Years Attended: 2017-2020</p>
          </div>
          <div className="school">
            <h3>Harrisburg University of Science and Technology (HU)</h3>
            <p>Years Attended: 2021-2023<br></br>B.Sc. in Computer and Information Sciences - Graduated May 2023</p>
          </div>
        </div>
        <div className="header-right-qualifications">
          <h1>
            <span style={{ color: '#1e90ff' }}>E</span>xperiences
          </h1>
          <div className="experience">
            <h3>Heir Atlas Project (Internship)</h3>
            <p>Contributed to the development of a secure <br></br> user-friendly web application for the Heir Atlas project.</p>
          </div>
          <div className="experience">
            <h3>Shadow work</h3>
            <p>Collaborated with external
developers <br></br> working to develop
microservices <br></br> for delivery
companies. <br></br>Implemented testing
functions <br></br>using Java and Spring
Boot.</p>
          </div>
          <div className="experience">
            <h3>Sycab (Internship Italy)</h3>
            <p>Helped and supported setup <br></br> and
debug of an industrial  <br></br> cheese
cutting machine using <br></br> PLC 
(Programmable Logical Controller)</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Qualifications;
