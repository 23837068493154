import React, {} from "react";
import "../styles/Contact.css";
import { MdCall, MdEmail } from "react-icons/md";
import image from '../Images/user.png'

const Contact = () => {

  const handleCall = () => {
    window.location.href = "tel:+1 610 483 4123";
  };

  const handleEmail = () => {
    window.location.href = "mailto:kaletnykvadymdev@gmail.com";
  };

  const handleText = () => {
    window.location.href = "sms:+1 610 483 4123";
  };

  return (
    <>
      <h1 id="contact-title">
        <span style={{ color: "#1e90ff" }}>C</span>ontact me
      </h1>
      <section id="contacts">
        <div className="header-left-skills">
        <img src={image} alt="" className="image" />
        </div>
        <div className="header-right-skills">
          <div class="contactCard">
            <div class="icon">
              <MdCall size={25} />
              <div class="title">Call / Text</div>
            </div>
            <div class="description">+1 610 483 4123</div>
            <div className="button-group">
              <button className="button" onClick={handleCall}>
                Call Me
              </button>
              <button className="button" onClick={handleText}>
                Text Me
              </button>
            </div>
          </div>

          <div class="contactCard">
            <div class="icon">
              <MdEmail size={25} />
              <div class="title">Email</div>
            </div>
            <div class="description">kaletnykvadymdev@gmail.com</div>
            <button class="button" onClick={handleEmail}>Email me</button> {/* Added button element */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
